import React from "react"
import Grid from '@material-ui/core/Grid'
import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import Navbar from './Navbar.js'
import Form from "./Form.js"
import "./backdrop.css"

import Footer from "./footer.js"

export default class GetQuote extends React.Component{
    constructor(props){
        super(props)
        document.body.classList.add("GetQuote");
        this.state = {width: 0, height: 0}
    }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({  width: window.innerWidth });
    this.forceUpdate()
  }


    render(){
        let percent
        if(this.state.width < 300){
            percent = "0"
        } else{
            percent = "10"
        }
        
        return(
          <>
        <div className="GetQuote">
        <div style={{backgroundColor:"#F3F3F399"}}>
            <Navbar bg={"#F4F4F4dc"}/>
        </div>
            
        <div style={{backgroundColor:"#F3F3F399", minWidth:1000}}>
            <Grid padding={0} container spacing={0} style={{height:"75vh"}}>
                <Grid item xs={7} spacing={0} style={{ maxHeight:"70vh",}}>           
                        <img src={require('../images/GetQuote.jpeg')}width="100%" height="100%"/>      
                     
                </Grid>
                <Grid  item xs={5} spacing={0} style={{maxHeight:"70vh",  paddingTop:0}}>
                   
                        <div style={{paddingTop:0, marginLeft:85, marginRight: 85, borderRadius:0,backgroundColor:"white",
                        height:"100%" }}>
                          
                         
                           <Form/>

                            </div>
                    

                </Grid> 
           
    
             </Grid>
           
       </div>
    </div>

    <Footer/>
    </>
        )
    }
}