import React from "react"

export default class Footer extends React.Component {
    render() {
        return (
            <>
            <div style={{paddingTop:"4px", backgroundColor:"grey", height:"15vh"}}>
<p style={{fontFamily:"Arial", color:"white", textAlign:"center", fontSize:16}}><b>
neisner@oxfordlitho.com	<span style={{paddingRight:"20px"}}/>
122 West 26th Street, 3rd Floor	<span style={{paddingRight:"20px"}}/>New York, NY 10001
<span style={{paddingRight:"20px"}}/>Phone: 212 243-7750 </b>

</p>

<div style={{backgroundColor:"grey"}}>

<p  style={{fontFamily:"Arial",textAlign:"center", color:"black", fontSize:14}}> © 2020. J2 Communications, All rights reserved.</p>
            <br/>
            </div>

            </div>
            
            </>
            
        )
    }
}